import React from 'react';

import Seo from '../components/Seo';

import sections from '../constants/sections';

function BunkerCustoms() {
  return <Seo title={sections.BunkerCustoms.title} />;
}

export default BunkerCustoms;
